import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, ref, onUnmounted, nextTick, watch } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    const isMobile = ref(false);
    function detectScreenSize(ty) {
      isMobile.value = window.innerWidth <= 768; // 假设屏幕宽度小于等于768px为移动设备
      !isMobile.value ? (sessionStorage.removeItem("fullPath"), sessionStorage.removeItem("path")) : "";
      if (ty == "load" && isMobile.value) {
        let fullPath = sessionStorage.getItem("fullPath");
        let path = sessionStorage.getItem("path");
        const pc = ["/", "/newroute"];
        if (pc.indexOf(path) != -1 || !path) {
          router.push("/mobile");
        } else {
          router.push(fullPath);
        }
        return;
      }
      redirectToProperRoute();
    }
    let isResizing = false;
    function redirectToProperRoute() {
      const currentPath = router.currentRoute.value.path;
      const fullPath = router.currentRoute.value.fullPath;
      const pc = ["/newroute"];
      const mobile = ["/mobile", "/newslist", "/newsdetail"];
      if (isMobile.value) {
        //   switch(currentPath) {
        //     case '/newroute':
        //       router.push(fullPath);
        //       break;
        //     case '/newsdetail':
        //       router.push('/newsdetail');
        //       break;
        //     default:
        //       router.push('/mobile');
        //       break;
        //   }
        router.push("/mobile");
      } else if (!isMobile.value && currentPath == "/mobile") {
        // switch(currentPath) {
        //   case '/newslist':
        //     router.push('/newslist');
        //     break;
        //   case '/newsdetail':
        //     router.push('/newroute');
        //     break;
        //   default:
        //     router.push('/');
        //     break;
        // }
        router.push("/");
      }
      isResizing = false;
    }
    onMounted(() => {
      detectScreenSize("load"); // 初始检测
      // window.addEventListener('resize', detectScreenSize); // 监听窗口大小变化
    });
    onUnmounted(() => {
      // 组件卸载前，移除resize事件监听
      // window.removeEventListener('resize', detectScreenSize);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};