import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import('../views/HomeView.vue')
  },
  {
    path: '/newroute',
    name: 'newroute',
    component: ()=> import('../views/newsRouter.vue')
  },
  {
    path: '/newlistpc',
    name: 'newlistpc',
    component: ()=> import('../views/newsList.vue')
  },
  {
    path: '/newsdetailpc',
    name: 'newsdetailpc',
    component: ()=> import('../views/newsDetail.vue')
  },
  {
    path: '/mobile',
    name: 'appIndex',
    component: ()=> import('../views/AppView.vue')
  },
  {
    path: '/newslist',
    name: 'newslist',
    component: ()=> import('../views/app/appNewsList.vue')
  },
  {
    path: '/newsdetail',
    name: 'newsdetail',
    component: ()=> import('../views/app/appNewsDetail.vue')
  },
  {
    path: '/newsdetailOld',
    name: 'newsdetailOld',
    component: ()=> import('../views/app/appNewsOld.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  // 切换路由时先取消所有请求
  sessionStorage.setItem('fullPath',to.fullPath)
  sessionStorage.setItem('path',to.path)
  next();
});

export default router
