import { createStore } from 'vuex'

export default createStore({
  state: {
    isMenu:false,
    isMobile: false,
    apiBaseUrl: 'https://ruoyi.femai.com',
  },
  getters: {
    getIsMenu:(state)=> state.isMenu,
    getIsMobile:(state)=> state.isMobile,
    apiBaseUrl: (state) => state.apiBaseUrl
  },
  mutations: {
    changeIsMobile(state,value){
      state.isMobile = value
    }
  },
  actions: {
    setIsMenu(data){
      this.isMenu = data
    },
    setIsMobile(content,data){
     content.commit('changeIsMobile',data)
    },
  },
  modules: {
  }
})
